<template>
	<div class="lg:w-56 lg:h-40 w-44 h-28 rounded-xl overflow-hidden shadow-2xl relative">
		<div class="absolute bottom-0 w-full lg:h-12 bg-gradient-to-t from-black to-transparent">
			<span class="block text-white capitalize font-bold p-3">
				{{ country.title }}
			</span>
		</div>
		<img class="w-full h-full object-cover" :src="`/images/countries/${country.image}`" :alt="country.title">
	</div>
</template>
<script>
export default {
	name: 'Country',
	props: [
		'country',
	]
}
</script>