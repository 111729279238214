<template>
	<header class="bg-secondary overflow-hidden">
		<div class="container mx-auto 2xl:max-w-screen-lg 2xl:pt-16 pt-10 px-4 2xl:w-5/6 lg:w-9/12 w-full lg:text-center">

			<!--Page Title-->
			<h1 class="2xl:text-6xl lg:text-5xl text-4xl xl:leading-2xl lg:leading-3xl leading-tight xl:mb-8 lg:mb-6 mb-4 relative tracking-tight font-black">
				<slot name="title"></slot>
			</h1>
			<h2 class="2xl:text-2xl text-xl font-bold mb-12">
				<slot name="description"></slot>
			</h2>

			<slot name="cta"></slot>
		</div>

		<!--Hero Image-->
		<div v-if="hero" class="container mx-auto 2xl:max-w-screen-2xl xl:max-w-screen-lg lg:max-w-nb xl:px-0 lg:px-8 px-4 -mb-12 relative">
			<slot name="hero-components"></slot>
			<div class="pepe">
				<img style="max-width: 1470px;" :class="{'shadow-lg': isShadow}" class="rounded-lg w-full mx-auto" :src="hero" alt="">
			</div>
		</div>

		<!--Wave BG-->
		<figure class="bg-wave-white bg-w-full bg-no-repeat bg-bottom h-12 w-full block transform lg:translate-y-1"></figure>
	</header>
</template>

<script>

export default {
	name: 'TheHeader',
	props: [
		'hero',
		'isShadow',
	],
}
</script>
