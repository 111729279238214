<template>
	<footer class="page-container bg-white pt-20 md:pb-4 pb-12">
		<div class="lg:flex lg:space-x-8 lg:mb-44 mb-8">
			<div class="xl:w-4/12 lg:w-5/12">

				<!--Optiomat-->
				<div class="mb-3">
					<img src="/images/logo.svg" alt="" class="xl:w-auto w-48">
					<span class="text-sm mt-2 block">Employee Share Option Plans. Automated.</span>
				</div>

				<!--Contact Us-->
				<div id="contact-us" class="lg:mt-10 mt-4 lg:mb-0 mb-2 lg:rounded-none lg:bg-white lg:p-0 bg-secondary p-4 rounded-lg">
					<p class="font-extrabold">Any question, concern or ideas to share with us?</p>

					<ArrowButton @click.native="showContactForm" v-if="! isContactForm">
						Contact Us
					</ArrowButton>

					<ContactForm v-if="isContactForm" />
				</div>
			</div>

			<!--Page links-->
			<div class="xl:w-8/12 lg:w-7/12 grid lg:grid-cols-4 grid-cols-2">
				<div v-for="item in navigation" class="lg:mt-0 mt-6">
					<span class="block font-black lg:mb-7 mb-4 text-xl">{{ item.title }}</span>
					<!--links-->
					<div v-for="link in item.children">
						<!--URL links-->
						<a v-if="link.url" :href="link.url" class="block py-2 hover:text-theme font-semibold xl:text-base text-sm">
							{{ link.title }}
						</a>
						<!--Route links-->
						<router-link v-if="link.route" :to="{name: link.route}" class="block py-2 hover:text-theme font-semibold xl:text-base text-sm">
							{{ link.title }}
						</router-link>
					</div>
				</div>
			</div>
		</div>

		<div class="lg:flex text-center justify-between pt-5 mt-5 border-t border-secondary">
			<div class="lg:pb-0 pb-4">
				<p class="font-semibold text-gray-500 text-sm">Copyright © 2021 Slovreal Trust s.r.o.. All rights reserved.</p>
			</div>
			<div>
				<span class="font-semibold text-sm py-1 px-5 inline-block">
					Terms & Conditions:
					<a :href="documentTermsConditionFounder" target="_blank" class="font-semibold text-sm inline-block text-theme hover:underline pr-1">Founder,</a>
					<a :href="documentTermsConditionEmployee" target="_blank" class="font-semibold text-sm inline-block text-theme hover:underline pr-1">Employee,</a>
					<a :href="documentTermsConditionTrust" target="_blank" class="font-semibold text-sm inline-block text-theme hover:underline">Trust</a>
				</span>
				<a :href="documentPrivacyPolicy" target="_blank" class="font-semibold text-sm py-1 px-5 pr-0 inline-block hover:text-theme">Privacy Policy</a>
			</div>
		</div>
	</footer>
</template>

<script>
import ArrowButton from "./ArrowButton";
import {mapGetters} from "vuex";
import ContactForm from "./ContactForm";

export default {
	name: 'TheFooter',
	components: {
		ContactForm,
		ArrowButton,
	},
	computed: {
		...mapGetters([
			'documentTermsConditionEmployee',
			'documentTermsConditionFounder',
			'documentTermsConditionTrust',
			'documentPrivacyPolicy',
			'isContactForm',
		])
	},
	data() {
		return {
			navigation: [
				{
					title: 'Optiomat',
					children: [
						{
							title: 'Home',
							route: 'Home',
						},
						{
							title: 'Why Optiomat',
							route: 'WhyOptiomat',
						},
						{
							title: 'Pricing',
							route: 'Pricing',
						},
					],
				},
				{
					title: 'How It Works',
					children: [
						{
							title: 'For Founders',
							route: 'HowItWorksFounders',
						},
						{
							title: 'For Employees',
							route: 'HowItWorksEmployees',
						},
						{
							title: 'For Trustees',
							route: 'HowItWorksTrustees',
						},
					],
				},
				{
					title: 'Benefits',
					children: [
						{
							title: 'For Companies',
							route: 'BenefitsForCompanies',
						},
						{
							title: 'For Employees',
							route: 'BenefitsForEmployees',
						},
					],
				},
				{
					title: 'Resources',
					children: [
						{
							title: 'Blog',
							route: 'Blog',
						},
						{
							title: 'User Guides',
							route: 'UserGuides',
						},
						{
							title: 'ESOP Glossary',
							route: 'EsopGlossary',
						},
						{
							title: 'YouTube',
							url: 'https://www.youtube.com/channel/UCnE_YS1s3XFnqTtRwfJ6WZw',
						},
						{
							title: 'LinkedIn',
							url: 'https://www.linkedin.com/company/optiomat/',
						},
					],
				},
			],
		}
	},
	methods: {
		showContactForm() {

			// Show form
			this.$store.commit('TOGGLE_CONTACT_FORM', true)
			//this.isVisibleContactForm = ! this.isVisibleContactForm

			// Auto focus
			//this.$nextTick(() => this.$refs.email.focus())
		}
	},
}
</script>
