<template>
	<div v-if="isContactFormPopup" class="fixed flex w-full h-full items-center z-40">
		<div class="page-container w-full max-w-screen-sm z-40 bg-white mx-auto p-8 rounded-xl shadow-xl">
			<span class="font-extrabold text-xl mb-4 block">
				Any question, concern or ideas to share with us?
			</span>
			<ContactForm />
		</div>
		<div
			@click="closePopup"
			class="bg-theme-dark absolute w-full h-full z-30 opacity-90 cursor-pointer"
		></div>
	</div>
	<TheNavigation />
	<router-view :key="$route.fullPath" />
	<GetOptiomat />
	<TheFooter />
</template>

<script>
	import TheNavigation from "./components/TheNavigation";
	import GetOptiomat from "./components/GetOptiomat";
	import TheFooter from "./components/TheFooter";
	import ContactForm from "./components/ContactForm";
	import {mapGetters} from "vuex";

	export default {
		name: 'App',
		components: {
			TheNavigation,
			ContactForm,
			GetOptiomat,
			TheFooter,
		},
		computed: {
			...mapGetters([
				'isContactFormPopup'
			])
		},
		methods: {
			closePopup() {
				this.$store.commit('TOGGLE_CONTACT_POPUP', false)
			}
		}
	}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap');

.page-container {
	@apply container mx-auto lg:px-8 px-4 2xl:max-w-screen-xl xl:max-w-screen-lg;
}

.input {
	@apply md:shadow;
	@apply rounded-md font-bold;
	@apply w-full py-3 px-4 mb-5;
	@apply focus:outline-none focus:ring-2 focus:border-transparent;
}

.tm-xs {
	@apply text-xs font-black transform -translate-y-2.5 inline-block;
}

.btn-solid-hover {
	@apply hover:opacity-90 transition-all duration-200 hover:shadow-xl;
 }

.router-link-active {
	color: #5151E7;
}

body, html {
	//background-color: #EDF1FD;
}

#app {
	font-family: Nunito, Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #161640;
}

#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}

.line {

	&:after {
		background: #5151E7;
		content: '';
		width: 100%;
		height: 15px;
		display: block;
		opacity: 0.3;
		transform: translateY(-18px);
		border-radius: 2px;
	}
}
</style>
