<template>
	<div class="shadow-lg hover:shadow-2xl transform hover:scale-105 transition-all xl:p-8 p-5">
		<img class="max-w-md mb-5 w-16" :src="icon" :alt="title">
		<h5 class="xl:text-2xl text-xl font-black leading-normal mb-3">
			{{ title }}<span class="text-theme text-sm font-black transform -translate-y-3 inline-block">TM</span> Workflow
		</h5>
		<p class="font-semibold">
			<slot></slot>
		</p>
	</div>
</template>
<script>
export default {
	name: 'WorkflowCard',
	props: [
		'title',
		'icon',
	]
}
</script>