import {createStore} from 'vuex'

export default createStore({
	state: {
		documentTermsConditionFounder: 'https://app.optiomat.com/docs/optiomat-founder-account-terms-&-conditions.pdf',
		documentTermsConditionEmployee: 'https://app.optiomat.com/docs/optiomat-employee-account-terms-&-conditions.pdf',
		documentTermsConditionTrust: 'https://app.optiomat.com/docs/optiomat-trust-account-terms-&-conditions.pdf',
		documentPrivacyPolicy: 'https://app.optiomat.com/docs/optiomat-privacy-policy.pdf',
		requestDemoLink: 'https://calendly.com/date-and-time/30min',
		registerButton: 'https://app.optiomat.com/register/founder',
		loginButton: 'https://app.optiomat.com/',
		isMobileMenu: false,
		isContactForm: false,
		isContactFormPopup: false,
		navigation: [
			{
				title: 'Home',
				route: 'Home',
			},
			{
				title: 'Why Optiomat',
				route: 'WhyOptiomat',
			},
			{
				title: 'How It Works',
				children: [
					{
						title: 'For Founders',
						route: 'HowItWorksFounders',
					},
					{
						title: 'For Employees',
						route: 'HowItWorksEmployees',
					},
					{
						title: 'For Trustees',
						route: 'HowItWorksTrustees',
					},
				]
			},
			{
				title: 'Benefits',
				children: [
					{
						title: 'For Companies',
						route: 'BenefitsForCompanies',
					},
					{
						title: 'For Employees',
						route: 'BenefitsForEmployees',
					},
				],
			},
			{
				title: 'Pricing',
				route: 'Pricing',
			},
			{
				title: 'Resources',
				children: [
					{
						title: 'Blog',
						route: 'Blog',
					},
					{
						title: 'User Guides',
						route: 'UserGuides',
					},
					{
						title: 'ESOP Glossary',
						route: 'EsopGlossary',
					},
				]
			},
		],
	},
	mutations: {
		TOGGLE_MOBILE_MENU(state, visibility) {
			state.isMobileMenu = !visibility
		},
		TOGGLE_CONTACT_FORM(state, visibility) {
			state.isContactForm = visibility
		},
		TOGGLE_CONTACT_POPUP(state, visibility) {
			state.isContactFormPopup = visibility
		},
	},
	actions: {
		toggleMobileMenu: ({commit, state}) => {
			commit('TOGGLE_MOBILE_MENU', state.isMobileMenu)
		}
	},
	getters: {
		documentTermsConditionEmployee: state => state.documentTermsConditionEmployee,
		documentTermsConditionFounder: state => state.documentTermsConditionFounder,
		documentTermsConditionTrust: state => state.documentTermsConditionTrust,
		documentPrivacyPolicy: state => state.documentPrivacyPolicy,
		isContactFormPopup: state => state.isContactFormPopup,
		requestDemoLink: state => state.requestDemoLink,
		registerButton: state => state.registerButton,
		isContactForm: state => state.isContactForm,
		loginButton: state => state.loginButton,
	},
	modules: {}
})
