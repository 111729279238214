<template xmlns="">
	<TheHeader hero="/images/screenshots/shareholding-and-plans.png" :is-shadow="false">
		<template v-slot:title>
			Employee Share Option Plans. <span class="line relative inline-block text-theme">Automated.</span>
		</template>
		<template v-slot:description>
			Deploy Employee Share Option Plans (ESOPs) <b>95% faster</b>, and <b>save thousands</b> with Optiomat. Apply a proven legal infrastructure overlaid by software to automate the most of ESOP experience.
		</template>
		<template v-slot:cta>
			<!--Buttons-->
			<StartOptiomatButtons class="lg:text-center 2xl:mt-14 mt-12" />

			<!--Demo Disclaimer-->
			<StartOptiomatDisclaimer class="lg:justify-center mt-12 mb-3" />
		</template>
		<template v-slot:hero-components>
			<img
				class="absolute 2xl:-left-16 xl:-left-32 -left-16 bottom-48 2xl:w-auto w-24 lg:block hidden"
				src="/images/trust-clip.png"
				alt="trust"
			>
			<img
				class="absolute 2xl:right-36 right-2 2xl:-top-56 -top-32 2xl:w-auto w-16 lg:block hidden"
				src="/images/founder-clip.png"
				alt="founder"
			>
			<img
				class="absolute 2xl:-right-20 xl:-right-40 -right-20 bottom-20 2xl:w-auto w-32 lg:block hidden"
				src="/images/employee-clip.png"
				alt="employee"
			>
		</template>
	</TheHeader>

	<div class="mt-12">
		<h3 data-aos="fade-left" class="page-container font-black lg:text-center lg:text-4xl text-2xl max-w-screen-sm mx-auto lg:mb-8 mb-6 leading-tight">
			Suitable for <br/><span class="text-theme">companies</span> from
		</h3>

		<kinesis-container data-aos="fade-left" :active="sliderActive" ref="countries" event="move" tag="div" class="lg:pl-24 pl-6 md:mb-20 mb-8 overflow-x-auto">
			<kinesis-element class="grid grid-rows-1 grid-flow-col lg:gap-12 gap-6" tag="div" :strength="sliderStrength" :minX="60" type="translate" axis="x" :originX="50" >
				<Country v-for="country in countriesFirst" :country="country" />
			</kinesis-element>
			<kinesis-element class="grid grid-rows-1 grid-flow-col lg:gap-12 gap-6 lg:mt-12 mt-6 lg:ml-24 ml-12" tag="div" :strength="sliderStrength" :minX="60" type="translate" axis="x" :originX="50" >
				<Country v-for="country in countriesSecond" :country="country" />
			</kinesis-element>
		</kinesis-container>
	</div>

	<!--Light-->
	<figure class="bg-wave-secondary bg-w-full bg-no-repeat bg-bottom h-12 w-full block transform lg:translate-y-1 translate-y-0.5"></figure>
	<div class="bg-secondary">
		<section class="page-container xl:max-w-screen-xl md:flex md:items-center md:space-x-12 md:py-20 py-10 md:space-y-0 space-y-16">
			<kinesis-container event="scroll" tag="div" class="text-center xl:w-7/12 md:w-6/12">
				<kinesis-element
					tag="img"
					src="/images/why-esop-with-optiomat.svg"
					:strength="60"
					type="translate"
					class="w-full inline-block md:max-w-md md:px-0 px-12"
				/>
			</kinesis-container>
			<div data-aos="fade-up" class="xl:w-5/12 md:w-6/12">
				<h3 class="font-black lg:text-4xl text-2xl max-w-screen-sm mx-auto lg:mb-8 mb-4 leading-tight">
					Why <span class="text-theme">ESOPs</span> with Optiomat
				</h3>
				<DescriptionCheck data-aos="fade-up">
					Zero financial implications for a company
				</DescriptionCheck>
				<DescriptionCheck data-aos="fade-up">
					Easy implementation for a company founder
				</DescriptionCheck>
				<DescriptionCheck data-aos="fade-up">
					Friendly taxation for employees
				</DescriptionCheck>
				<DescriptionCheck data-aos="fade-up">
					Cost and time savings for all involved
				</DescriptionCheck>
				<DescriptionCheck data-aos="fade-up">
					Specifically designed for limited liability companies such as BV, GmbH, SARL, SRL, S.L., s.r.o., Sp. z o.o., Oy, OÜ, KÜB, KB, KS, Lda., d.o.o., IVS, ApS, kft., TOB (OOO)
				</DescriptionCheck>
				<DescriptionCheck data-aos="fade-up">
					Proven Dutch model with international applicability
				</DescriptionCheck>
			</div>
		</section>
		<figure class="bg-wave-white bg-w-full bg-no-repeat bg-bottom h-12 w-full block transform lg:translate-y-1 translate-y-0.5"></figure>
	</div>

	<div data-aos="fade-up" class="page-container mt-20">
		<h3 class="font-black lg:text-4xl text-3xl lg:text-center max-w-screen-sm mx-auto lg:mb-8 mb-4 leading-tight">
			See how Optiomat can make <span class="text-theme">ESOPs easier</span> for:
		</h3>
	</div>

	<tabs
		data-aos="fade-up"
		nav-class="md:page-container flex overflow-x-auto md:justify-center md:pl-0 pl-4 pb-4"
		nav-item-class="font-bold px-6 py-3.5 lg:mx-3 whitespace-nowrap lg:text-lg text-sm"
		nav-item-active-class="bg-theme rounded-full shadow-lg"
		nav-item-link-class="hover:opacity-60 transition-opacity"
		nav-item-link-active-class="text-white"
		:options="{ useUrlFragment: false }"
	>
		<tab cache-lifetime="0" panel-class="page-container xl:pt-6 pt-3" name="Company Founder">
			<h4 class="font-extrabold lg:text-center lg:text-xl text-lg max-w-2xl mx-auto mb-8">
				Optiomat makes ESOP workflow simple and streamlined. Start each process with a single button and finish it with a few clicks.
			</h4>
			<div class="2xl:max-w-screen-xl mx-auto lg:grid justify-center grid-cols-3 xl:gap-10 lg:gap-6">
				<WorkflowCard
					data-aos="fade-up"
					class="rounded-xl lg:mb-0 mb-6"
					v-for="workflow in founderWorkflows"
					:title="workflow.title"
					:icon="workflow.icon"
				>
					{{ workflow.description }}
				</WorkflowCard>
			</div>

			<router-link data-aos="fade-up" :to="{name: 'HowItWorksFounders'}">
				<ArrowButton class="justify-center lg:mt-14 mt-10">
					How It Works
				</ArrowButton>
			</router-link>

			<HowToBenefit
				data-aos="fade-up"
				route="BenefitsForCompanies"
				description="Attract and incentivize talent. Lay the foundation of a committed workforce."
			/>
		</tab>
		<tab panel-class="page-container xl:pt-6 pt-3" name="Employees">
			<h4 class="font-extrabold lg:text-center lg:text-xl text-lg max-w-2xl mx-auto mb-8">
				Optiomat offers completely paperless ESOP experience, keeps you informed and demonstrates the value of your option packages before you make any important decision.
			</h4>
			<div class="2xl:max-w-screen-xl mx-auto lg:grid justify-center grid-cols-3 xl:gap-10 lg:gap-6">
				<WorkflowCard
					data-aos="fade-up"
					class="rounded-xl lg:mb-0 mb-6"
					v-for="workflow in employeeWorkflows"
					:title="workflow.title"
					:icon="workflow.icon"
				>
					{{ workflow.description }}
				</WorkflowCard>
			</div>

			<router-link data-aos="fade-up" :to="{name: 'HowItWorksEmployees'}">
				<ArrowButton class="justify-center lg:mt-14 mt-10">
					How It Works
				</ArrowButton>
			</router-link>

			<HowToBenefit
				data-aos="fade-up"
				route="BenefitsForEmployees"
				description="Participate on a company's success. Be part of a meaningful adventure"
			/>
		</tab>
		<tab panel-class="page-container xl:pt-6 pt-3" name="Trustees">
			<h4 class="font-extrabold lg:text-center lg:text-xl text-lg max-w-2xl mx-auto mb-8">
				Optiomat digitizes most of specialized ESOP administrative tasks and enables to meet the growing demand for cost-effective ESOP trust office solutions.
			</h4>
			<div class="2xl:max-w-screen-xl mx-auto lg:grid justify-center grid-cols-3 xl:gap-10 lg:gap-6">
				<WorkflowCard
					data-aos="fade-up"
					class="rounded-xl lg:mb-0 mb-6"
					v-for="workflow in trustWorkflows"
					:title="workflow.title"
					:icon="workflow.icon"
				>
					{{ workflow.description }}
				</WorkflowCard>
			</div>

			<router-link data-aos="fade-up" :to="{name: 'HowItWorksTrustees'}">
				<ArrowButton class="justify-center lg:mt-14 mt-10">
					How It Works
				</ArrowButton>
			</router-link>
		</tab>
	</tabs>
</template>

<script>
	import StartOptiomatDisclaimer from "../components/StartOptiomatDisclaimer";
	import StartOptiomatButtons from "../components/StartOptiomatButtons";
	import DescriptionCheck from "../components/DescriptionCheck";
	import ArrowButton from "../components/ArrowButton";
	import TheHeader from "../components/TheHeader";
	import {Tab, Tabs} from 'vue3-tabs-component';
	import WorkflowCard from "./WorkflowCard";
	import HowToBenefit from "./HowToBenefit";
	import Country from "./Country";

	export default {
		name: 'Home',
		components: {
			Country,
			HowToBenefit,
			StartOptiomatDisclaimer,
			StartOptiomatButtons,
			DescriptionCheck,
			WorkflowCard,
			ArrowButton,
			TheHeader,
			Tabs,
			Tab,
		},
		data() {
			return {
				sliderActive: true,
				sliderStrength: 3100,
				countriesFirst: [
					{
						image: 'albania.jpeg',
						title: 'albania',
					},
					{
						image: 'austria.jpeg',
						title: 'austria',
					},
					{
						image: 'croatia.jpeg',
						title: 'croatia',
					},
					{
						image: 'cyprus.jpeg',
						title: 'cyprus',
					},
					{
						image: 'czechia.jpeg',
						title: 'czechia',
					},
					{
						image: 'denmark.jpeg',
						title: 'denmark',
					},
					{
						image: 'finland.jpeg',
						title: 'finland',
					},
					{
						image: 'france.jpeg',
						title: 'france',
					},
					{
						image: 'hungary.jpeg',
						title: 'hungary',
					},
					{
						image: 'iceland.jpeg',
						title: 'iceland',
					},
					{
						image: 'ireland.jpeg',
						title: 'ireland',
					},
					{
						image: 'latvia.jpeg',
						title: 'latvia',
					},
					{
						image: 'liechtenstein.jpeg',
						title: 'liechtenstein',
					},
					{
						image: 'lithuania.jpeg',
						title: 'lithuania',
					},
					{
						image: 'moldova.jpeg',
						title: 'moldova',
					},
					{
						image: 'monaco.jpeg',
						title: 'monaco',
					},
					{
						image: 'montenegro.jpeg',
						title: 'montenegro',
					},
					{
						image: 'netherlands.jpeg',
						title: 'netherlands',
					},
				],
				countriesSecond: [
					{
						image: 'norway.jpeg',
						title: 'norway',
					},
					{
						image: 'poland.jpeg',
						title: 'poland',
					},
					{
						image: 'romania.jpeg',
						title: 'romania',
					},
					{
						image: 'serbia.jpeg',
						title: 'serbia',
					},
					{
						image: 'slovakia.jpeg',
						title: 'slovakia',
					},
					{
						image: 'slovenia.jpeg',
						title: 'slovenia',
					},
					{
						image: 'switzerland.jpeg',
						title: 'switzerland',
					},
					{
						image: 'ukraine.jpeg',
						title: 'ukraine',
					},
					{
						image: 'united-kingdom.jpeg',
						title: 'united-kingdom',
					},
					{
						image: 'luxembourg.jpeg',
						title: 'Luxembourg',
					},
					{
						image: 'malta.jpeg',
						title: 'Malta',
					},
					{
						image: 'belgium.jpeg',
						title: 'Belgium',
					},
					{
						image: 'bulgaria.jpeg',
						title: 'Bulgaria',
					},
					{
						image: 'estonia.jpeg',
						title: 'Estonia',
					},
					{
						image: 'germany.jpeg',
						title: 'Germany',
					},
					{
						image: 'greece.jpeg',
						title: 'Greece',
					},
					{
						image: 'italy-country.jpeg',
						title: 'Italy',
					},
					{
						image: 'sweden.jpeg',
						title: 'Sweden',
					},
				],
				founderWorkflows: [
					{
						title: 'OptioPool',
						icon: '/images/workflows/optiotrack-workflow.svg',
						description: 'Establish an option pool over a part of your shares in a few clicks.',
					},
					{
						title: 'OptioPlan',
						icon: '/images/workflows/optioplan-workflow.svg',
						description: 'Create template-driven option plans without a huge learning curve. ',
					},
					{
						title: 'OptioSign',
						icon: '/images/workflows/optiosign-workflow.svg',
						description: 'Award option plans by signing digital documents right from your email inbox. ',
					},
					{
						title: 'OptioManager',
						icon: '/images/workflows/optiomanager-workflow.svg',
						description: 'Settle option plans in case of a new event such as a good leaver, bad leaver or sale of the company at exit.',
					},
					{
						title: 'OptioView',
						icon: '/images/workflows/optioview-workflow.svg',
						description: 'Get the complete picture of all your ESOPs through our simple and user-friendly web-based service.',
					},
					{
						title: 'OptioPlus',
						icon: '/images/workflows/optioplus-workflow.svg',
						description: 'Appoint a trustee to convert option pool shares to share certificates as a separate digital asset for employee participation.',
					},
				],
				employeeWorkflows: [
					{
						title: 'OptioPlay',
						icon: '/images/workflows/optioplay-workflow.svg',
						description: 'Receive, accept and exercise share options in a few clicks. Track all of your ESOPs, activities, transactions and earnings.',
					},
				],
				trustWorkflows: [
					{
						title: 'OptioTrust',
						icon: '/images/workflows/optiotrust-workflow.svg',
						description: 'Issue and register digital share certificates. Allot share certificates in automated regime. Record and store all data in a safe cloud-based platform. ',
					},
				],
			}
		},
		methods: {
			isMobile() {
				const toMatch = [
					/Android/i,
					/webOS/i,
					/iPhone/i,
					/iPad/i,
					/iPod/i,
					/BlackBerry/i,
					/Windows Phone/i
				]

				return toMatch.some(toMatchItem => {
					return navigator.userAgent.match(toMatchItem)
				})
			}
		},
		created() {
			this.sliderActive = ! this.isMobile()

			this.sliderStrength = 18 * 224
		}
	}
</script>
