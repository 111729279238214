<template>
	<div class="flex items-top mb-5">
		<div class="mr-3">
			<svg class="stroke-current text-theme" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
				<polyline points="20 6 9 17 4 12"></polyline>
			</svg>
		</div>
		<p class="text-secondary font-extrabold">
			<slot></slot>
		</p>
	</div>
</template>
<script>
export default {
	name: 'DescriptionCheck'
}
</script>