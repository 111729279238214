<template>
	<div class="flex items-center mt-1 cursor-pointer">
		<span class="text-theme font-bold block">
			<slot></slot>
		</span>
		<svg class="transform scale-75 stroke-current text-theme ml-1 transition-all" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
			<line x1="5" y1="12" x2="19" y2="12"></line>
			<polyline points="12 5 19 12 12 19"></polyline>
		</svg>
	</div>
</template>
<script>
export default {
	name: 'ArrowButton',
}
</script>

<style lang="scss" scoped>
	.flex:hover {

		.ml-1 {
			margin-left: 1rem;
		}
	}
</style>