<template>
	<nav style="position: -webkit-sticky" :class="{'bg-white': isMobileMenu, 'bg-white 2xl:py-5 shadow-sm': isSticky, '2xl:py-5': !isSticky, 'bg-secondary': ! isSticky && !isMobileMenu}" class="sticky top-0 flex flex-nowrap items-center justify-between py-4 lg:px-8 px-4 z-20 transition-all duration-300">
		<div class="flex items-center z-20 relative">
			<div @click="toggleMobileMenu" class="lg:hidden block relative mr-4">
				<div v-if="! isMobileMenu">
					<span style="height: 2px" class="w-4 bg-primary rounded-full block mb-1 mt-1"></span>
					<span style="height: 2px" class="w-4 bg-primary rounded-full block mb-1 ml-0.5"></span>
					<span style="height: 2px" class="w-4 bg-primary rounded-full block mb-1"></span>
				</div>
				<div v-if="isMobileMenu" style="margin-right: 2px">
					<span style="height: 2px" class="transform rotate-45 translate-y-1 w-4 bg-theme rounded-full block mb-1"></span>
					<span style="height: 2px" class="transform -rotate-45 -translate-y-0.5 w-4 bg-theme rounded-full block mb-1"></span>
				</div>
			</div>

			<router-link :to="{name: 'Home'}">
				<img src="/images/logo.svg" alt="" class="xl:w-auto w-32">
			</router-link>
		</div>

		<ul class="lg:block hidden">
			<li v-for="(item, i) in navigation" @click="openSubmenu(i)" class="group inline-block relative">
				<router-link v-if="item.route" @click="closeMenu" :to="{name: item.route}" class="inline-block font-extrabold 2xl:text-base text-sm group-hover:text-theme 2xl:px-6 2xl:px-3 px-3 py-3 z-20">
					{{ item.title }}
				</router-link>

				<span v-else class="inline-block font-extrabold 2xl:text-base text-sm group-hover:text-theme 2xl:px-6 2xl:px-3 px-3 py-3 z-20 cursor-pointer">
					{{ item.title }}
					<svg :class="{'transform rotate-180': submenuIndex === i}" class="transform scale-75 transition-all duration-300 inline-block group-hover:text-theme stroke-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
						<polyline points="6 9 12 15 18 9"></polyline>
					</svg>
				</span>

				<ul v-if="item.children && submenuIndex === i" class="bg-white rounded-lg shadow-xl absolute top-15 2xl:left-5 z-10 py-3">
					<router-link @click.stop="closeMenu" v-for="item in item.children" :to="{name: item.route}" class="block font-extrabold hover:text-theme px-5 py-3.5 text-sm whitespace-nowrap">
						{{ item.title }}
					</router-link>
				</ul>
			</li>
		</ul>

		<div class="text-right relative z-20">
			<a :href="loginButton" target="_blank" class="font-extrabold 2xl:pr-7 pr-4 py-5 whitespace-nowrap 2xl:text-base text-xs">Log In</a>
			<a :href="registerButton" target="_blank" class="btn-solid-hover bg-theme text-white font-extrabold rounded-lg whitespace-nowrap shadow-lg 2xl:px-5 2xl:py-3.5 px-3 py-2.5 2xl:text-base text-xs">Join Optiomat</a>
		</div>
	</nav>

	<div v-if="$store.state.isMobileMenu" class="lg:hidden max-h-screen overflow-y-auto bg-white sticky pt-4 z-10 left-0 right-0 top-0 shadow-md">
		<ul class="block">
			<li v-for="(item, i) in navigation" @click="openSubmenu(i)" class="group block relative">
				<router-link v-if="item.route" @click="closeMenu" :to="{name: item.route}" class="block font-extrabold text-sm px-6 py-3.5 z-20">
					{{ item.title }}
				</router-link>

				<span v-else class="block font-extrabold text-sm px-6 py-3.5 z-20 cursor-pointer">
					{{ item.title }}
					<svg :class="{'transform rotate-180': submenuIndex === i}" class="transform scale-75 transition-all inline-block stroke-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
						<polyline points="6 9 12 15 18 9"></polyline>
					</svg>
				</span>

				<ul v-if="item.children && submenuIndex === i" class="bg-white rounded-lg z-10">
					<router-link v-for="item in item.children" @click="closeMenu" :to="{name: item.route}" class="block font-bold text-sm pl-12 py-3.5 z-20">
						{{ item.title }}
					</router-link>
				</ul>
			</li>
		</ul>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: 'TheNavigation',
	computed: {
		...mapGetters([
			'registerButton',
			'loginButton',
		]),
		isMobileMenu() {
			return this.$store.state.isMobileMenu
		},
		navigation() {
			return this.$store.state.navigation
		},
	},
	data() {
		return {
			isSticky: false,
			submenuIndex: undefined,
		}
	},
	methods: {
		closeMenu() {
			this.submenuIndex = undefined

			if (this.isMobileMenu) {
				this.$store.dispatch('toggleMobileMenu')
			}
		},
		toggleMobileMenu() {
			this.$store.dispatch('toggleMobileMenu')
		},
		openSubmenu(i) {
			if (this.submenuIndex === i)
				this.submenuIndex = undefined

			else if (typeof this.submenuIndex !== 'undefined' && this.submenuIndex !== i)
				this.submenuIndex = i

			else if (typeof this.submenuIndex === 'undefined')
				this.submenuIndex = i
		},
		scrolling() {
			if (window.scrollY > 0)
				this.isSticky = true
			else
				this.isSticky = false
		}
	},
	mounted() {
		window.addEventListener('scroll', this.scrolling)
	}
}
</script>
