<template>
	<div class="md:flex items-center md:space-x-8 xl:max-w-screen-lg lg:max-w-screen-md mx-auto mt-24">
		<div>
			<h6 class="text-4xl tracking-tight font-black mb-2">
				How To <span class="text-theme">Benefit</span>
			</h6>
			<p class="font-bold md:mb-14 mb-7">
				{{ description }}
			</p>
			<router-link :to="{name: route}">
				<ArrowButton>
					Learn More
				</ArrowButton>
			</router-link>
		</div>
		<div>
			<img src="/images/company-value-and-culture.svg" alt="">
		</div>
	</div>
</template>
<script>
import ArrowButton from "../components/ArrowButton"

export default {
	name: 'HowToBenefit',
	props: [
		'description',
		'route',
	],
	components: {
		ArrowButton
	},
}
</script>