<template>
	<div>
		<a
			:href="registerButton"
			target="_blank"
			class="btn-solid-hover bg-theme lg:text-lg text-sm text-white font-extrabold lg:px-8 lg:py-4 px-4 py-3.5 lg:mr-5 mr-3 rounded-lg whitespace-nowrap shadow-lg"
		>
			Start Trial as Founder
		</a>
		<a
			:href="requestDemoLink"
			target="_blank"
			class="hover:bg-primary hover:text-white transition-all border-3 border-primary lg:text-lg text-sm text-primary font-extrabold lg:px-8 lg:py-3.5 px-4 py-3 lg:ml-5 ml-3 rounded-lg whitespace-nowrap"
		>
			Request a Demo
		</a>
	</div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
	name: 'StartOptiomatButtons',
	computed: {
		...mapGetters([
			'requestDemoLink',
			'registerButton',
		])
	}
}
</script>
