<template>
	<div class="md:mt-20 mt-10">
		<figure class="bg-wave-secondary bg-w-full bg-no-repeat bg-bottom h-12 w-full block transform translate-y-0.5"></figure>
		<div class="bg-secondary pt-16 overflow-x-hidden">
			<section class="page-container flex md:flex-nowrap flex-wrap items-center md:space-x-14">
				<div class="lg:w-6/12 w-full">
					<h4 class="2xl:text-4xl md:text-3xl text-2xl 2xl:leading-2xl lg:leading-3xl leading-tight lg:mb-8 mb-4 relative tracking-tight font-black">
						Managing ESOP agenda in excel is out of fashion. Start using more effective tools.
					</h4>

					<p class="md:text-xl text-lg mb-14 font-bold">
						Get the founder's account 14 day free trial or get a guided tour and ask us about Optiomat's features and benefits.
					</p>

					<kinesis-container event="scroll" tag="div" class="2xl:w-7/12 md:w-6/12">
						<kinesis-element tag="div" :strength="20" type="translate">
							<StartOptiomatButtons class="mb-12 transform md:scale-90 origin-left" />
						</kinesis-element>
					</kinesis-container>

					<StartOptiomatDisclaimer />
				</div>

				<div class="lg:w-6/12 w-full">
					<img class="w-max 2xl:max-w-6xl lg:max-w-4xl h-auto md:mt-0 mt-4 md:rounded-tl-xl rounded-tl-lg" src="/images/screenshots/get-esop-agenda.png" alt="">
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import StartOptiomatDisclaimer from "./StartOptiomatDisclaimer";
import StartOptiomatButtons from "./StartOptiomatButtons";

export default {
	name: 'GetOptiomat',
	components: {
		StartOptiomatDisclaimer,
		StartOptiomatButtons,
	},
	data() {
		return {}
	},
	methods: {},
	created() {

	}
}
</script>
