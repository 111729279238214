<template>
	<form class="mt-4" @submit.prevent="contactForm" ref="contactForm">
		<input v-model="email" placeholder="Type your email..." ref="email" class="input focus:ring-theme" type="email" required>
		<textarea v-model="message" placeholder="Leave your message here..." rows="7" class="input focus:ring-theme" required></textarea>

		<b v-if="isError" class="block mb-4 -mt-4 text-red-600">
			Something went wrong, please try it again.
		</b>

		<b v-if="isSuccess" class="block mb-4 -mt-4 text-green-600">
			Your message was send successfully.
		</b>

		<button :disabled="isLoading" type="submit" :class="{'opacity-60': isLoading}" class="bg-theme md:w-auto w-full text-white font-extrabold rounded-lg whitespace-nowrap shadow-lg xl:px-5 xl:py-3.5 px-3 py-2.5 xl:text-base text-xs">
			{{ submitText }}
		</button>
	</form>
</template>
<script>
import axios from 'axios'

export default {
	name: 'ContactForm',
	data() {
		return {
			email: '',
			message: '',
			isSubmitted: false,
			isLoading: false,
			isSuccess: false,
			isError: false,
		}
	},
	computed: {
		submitText() {
			return this.isLoading ? 'Sending Message...' : 'Send Message'
		}
	},
	methods: {
		async contactForm() {

			// Start loading
			this.isLoading = true

			// Send request to get user token
			axios
				.post('https://app.optiomat.com/api/contact/', {
					email: this.email,
					message: this.message,
				})
				.then(() => {
					this.isSuccess = true

					this.email = undefined
					this.message  = undefined
				})
				.catch(() => {
					this.isError = true
				})
				.finally(() => {
					this.isLoading = false
				})
		}
	},
}
</script>