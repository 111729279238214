<template>
	<div class="flex">
		<div class="2xl:mr-6 mr-4" v-for="benefit in benefits">
			<svg class="transform scale-75 stroke-current text-theme inline-block" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
				<polyline points="20 6 9 17 4 12"></polyline>
			</svg>
			<span class="text-sm font-bold ml-1">
				{{ benefit }}
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'StartOptiomatDisclaimer',
	data() {
		return {
			benefits: [
				'14 Day Free Trial',
				'No Credit Card',
			],
		}
	},
}
</script>
