import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/why-optiomat',
		name: 'WhyOptiomat',
		component: function () {
			return import(/* webpackChunkName: "why-optiomat" */ '../views/WhyOptiomat.vue')
		}
	},
	{
		path: '/blog',
		name: 'Blog',
		component: function () {
			return import(/* webpackChunkName: "blog" */ '../views/Blog.vue')
		},
	},
	{
		path: '/blog/:article',
		name: 'BlogArticle',
		component: function () {
			return import(/* webpackChunkName: "article" */ '../views/BlogArticle.vue')
		}
	},
	{
		path: '/how-it-works-for-founders',
		name: 'HowItWorksFounders',
		component: function () {
			return import(/* webpackChunkName: "how-it-work-for-founders" */ '../views/HowItWorksFounders.vue')
		}
	},
	{
		path: '/how-it-works-for-employees',
		name: 'HowItWorksEmployees',
		component: function () {
			return import(/* webpackChunkName: "how-it-work-for-employees" */ '../views/HowItWorksEmployees.vue')
		}
	},
	{
		path: '/how-it-works-for-trustees',
		name: 'HowItWorksTrustees',
		component: function () {
			return import(/* webpackChunkName: "how-it-work-for-trustees" */ '../views/HowItWorksTrustees.vue')
		}
	},
	{
		path: '/benefits-for-companies',
		name: 'BenefitsForCompanies',
		component: function () {
			return import(/* webpackChunkName: "benefits-for-companies" */ '../views/BenefitsForCompanies.vue')
		}
	},
	{
		path: '/benefits-for-employees',
		name: 'BenefitsForEmployees',
		component: function () {
			return import(/* webpackChunkName: "benefits-for-employees" */ '../views/BenefitsForEmployees.vue')
		}
	},
	{
		path: '/pricing',
		name: 'Pricing',
		component: function () {
			return import(/* webpackChunkName: "pricing" */ '../views/Pricing.vue')
		}
	},
	{
		path: '/user-guides',
		name: 'UserGuides',
		component: function () {
			return import(/* webpackChunkName: "user-guides" */ '../views/UserGuides.vue')
		}
	},
	{
		path: '/esop-glossary',
		name: 'EsopGlossary',
		component: function () {
			return import(/* webpackChunkName: "esop-glossary" */ '../views/ESOPGlossary.vue')
		}
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.afterEach(() => {
	window.scrollTo({top: 0})
})

export default router
